import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="Modern Slavery and Human Trafficking Statement" mdxType="Seo" />
    <h1>{`Modern Slavery and Human Trafficking Statement`}</h1>
    <h2>{`Introduction`}</h2>
    <p>{`This Modern Slavery and Human Trafficking Statement is a response to Section 54(1), Part 6 of the Modern Slavery Act 2015 and relates to actions and activities for the financial year ending 05 April 2021.`}</p>
    <p>{`P03 Ventures Ltd (‘the Company’, ‘we’, ‘us’ or ‘our’) is committed to preventing slavery and human trafficking violations in its own operations, its supply chain, and its products. We have zero-tolerance towards slavery and require our supply chain to comply with our values.`}</p>
    <h2>{`Organisational structure`}</h2>
    <p>{`Po3 Ventures Ltd has business operations in the United Kingdom.`}</p>
    <p>{`We operate in the Management Consultancy sector. The nature of our supply chains is as follows: We work with a number of key direct suppliers, who provide us with goods for business operations, as well as services, such as: outsourced business processes, service and business design capabilities, software and marketing services.`}</p>
    <p>{`For more information about the Company, please visit our website: `}<a parentName="p" {...{
        "href": "http://www.po3.ventures",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`www.po3.ventures`}</a>{`.`}</p>
    <h2>{`Policies`}</h2>
    <p>{`We operate a number of internal policies to ensure that we are conducting business in an ethical and transparent manner.`}</p>
    <p>{`These include the following:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Supplier code of conduct`}</em>{` - We vet suppliers based on their adherence to B-corp standards`}</li>
    </ul>
    <p>{`We make sure our suppliers are aware of our policies and adhere to the same standards.`}</p>
    <h2>{`Due Diligence`}</h2>
    <p>{`As part of our efforts to monitor and reduce the risk of slavery and human trafficking occurring in our supply chains, we have adopted the following due diligence procedures:`}</p>
    <ul>
      <li parentName="ul">{`Suppliers sourced from B-Corp listed organisations`}</li>
      <li parentName="ul">{`Work with vetted, known individuals`}</li>
    </ul>
    <p>{`Our due diligence procedures aim to:`}</p>
    <ul>
      <li parentName="ul">{`Identify and action potential risks in our business and supply chains.`}</li>
      <li parentName="ul">{`Monitor potential risks in our business and supply chains`}</li>
      <li parentName="ul">{`Reduce the risk of slavery and human trafficking occurring in our business and supply chains.`}</li>
    </ul>
    <h2>{`Risk and compliance`}</h2>
    <p>{`The Company has evaluated the nature and extent of its exposure to the risk of slavery and human trafficking occurring in its UK supply chain through:`}</p>
    <ul>
      <li parentName="ul">{`Evaluating the slavery and human trafficking risks of each new supplier.`}</li>
      <li parentName="ul">{`Reviewing on a regular basis all aspects of the supply chain based on supply chain mapping`}</li>
    </ul>
    <p>{`We do not consider that we operate in a high-risk environment because the majority of our supply chain is based in the UK and in low-risk industries, such as internet software and services.`}</p>
    <p>{`We do not tolerate slavery and human trafficking in our supply chains. Where there is evidence of failure to comply with our policies and procedures by any of our suppliers, we will seek to terminate our relationship with that supplier immediately.`}</p>
    <h2>{`Effectiveness`}</h2>
    <p>{`The Company uses Key Performance Indicators (KPIs) to measure its effectiveness and ensure that slavery and human trafficking is not taking place in its business and supply chains. These KPIs are as follows:`}</p>
    <ul>
      <li parentName="ul">{`We will carry out a regular audit of suppliers - 100% of suppliers each year.`}</li>
    </ul>
    <h2>{`Next steps`}</h2>
    <p>{`In the next financial year, we intend to take the following steps to tackle slavery and human trafficking by:`}</p>
    <ul>
      <li parentName="ul">{`Completing B-corp accreditation`}</li>
    </ul>
    <p>{`The statement was approved by the board of directors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
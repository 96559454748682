import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "./layout"
import Seo from "./seo"
import { MDXProvider } from "@mdx-js/react"
import { Button, Paragraph, Box, Heading } from "grommet"

import SignUp from "./signup"

const shortcodes = {
  Link,
  Button,
  Seo,
  Img,
  SignUp,
  h1: props => (
    <Heading level="1" size="medium" margin={{ top: "medium" }}>
      {props.children}
    </Heading>
  ),
  h2: props => (
    <Heading level="2" size="small" margin={{ top: "medium" }}>
      {props.children}
    </Heading>
  ),
  h3: props => (
    <Heading level="3" size="small" margin={{ top: "medium" }}>
      {props.children}
    </Heading>
  ),
  h4: props => (
    <Heading level="4" size="small" margin={{ top: "medium" }}>
      {props.children}
    </Heading>
  ),
  Paragraph,
} // Provide common components here
export default function Page({ children }) {
  return (
    <Layout>
      <Box>
        <Box>
          <Box
            margin={{ vertical: "xlarge" }}
            pad={{ horizontal: "medium" }}
            width="large"
            alignSelf="center"
            align="start"
          >
            <MDXProvider components={shortcodes}>{children}</MDXProvider>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}
